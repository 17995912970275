import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHeadsObserver } from '../hooks';

export default function TableOfContents() {
    const { pathname } = useLocation();
    const [headings, setHeadings] = useState([]);
    const { activeId } = useHeadsObserver();

    // Get all headings every time a new page is rendered
    useEffect(() => {
        const elements = Array.from(document.querySelectorAll('h1'))
            .map((elem) => ({
                id: elem.id,
                text: elem.innerText
            }));
        setHeadings(elements);
    }, [pathname]);

    return (
        <nav className="mx-10 pt-20 lg:sticky lg:self-start lg:top-16 sm:mx-20 lg:mx-0 lg:mr-20 lg:pt-0 sm:w-[32rem] lg:w-auto grow">
            <ul>
                {
                    headings.map(heading => (
                        <li key={heading.id}>
                            <a
                                href={`#${heading.id}_header`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    document.querySelector(`#${heading.id}_header`)
                                        .scrollIntoView({ behavior: 'smooth' });
                                }}
                                className={
                                    activeId === heading.id ? "" : "text-neutral-400"
                                }
                            >
                                {heading.text}
                            </a>
                        </li>
                    ))
                }
            </ul>
        </nav>
    );
};
