export default function Hyperlink({ text, link }) {
    return (
        <a
            href={`${link}`}
            className="text-orange-600 hover:underline"
        >
            { text }
        </a>
    );
};
