import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Return active element's id
// Source: https://blog.logrocket.com/create-table-contents-highlighting-react/
export function useHeadsObserver() {
    const { pathname } = useLocation();
    const [activeId, setActiveId] = useState('');
    const observer = useRef();

    // Start observing all h1 headings every time new page is rendered
    useEffect(() => {
        const handleObserver = (entries) => {
            entries.forEach((entry) => {
                if (entry?.isIntersecting) {
                    setActiveId(entry.target.id);
                }
            });
        };

        observer.current = new IntersectionObserver(handleObserver);
        const elements = document.querySelectorAll('h1');
        elements.forEach((elem) => observer.current.observe(elem));

        // Cleanup
        return () => observer.current?.disconnect();
    }, [pathname]);

    return { activeId };
};
