import { NavLink } from 'react-router-dom';
import Hyperlink from '../components/Hyperlink';
import Image from '../components/Image';

export default function Landing() {
    return (
        <div className="flex-none mx-10 sm:mx-20 sm:w-[32rem]">
            <div className="pt-20 lg:pt-28">
                <h2 className="mb-4 font-sans text-sm font-bold tracking-widest text-orange-600" id="landing1_header">STORY</h2>
                <h1 className="font-sans text-3xl font-medium mb-14" id="landing1">
                    Connecting people through technology
                </h1>
                <div className="font-sans">
                    <p className="mb-5">
                        Marshall McLuhan in <i>Understanding Media</i> wrote,
                        "all technologies are extensions of our physical
                        and nervous systems." Taking that a step further, the
                        most useful technologies are the ones whose extensions
                        allow for high throughputs of information. From my two
                        years at Apple building AI content creation tools for
                        mixed reality, I've been wowed at how mixed reality
                        uses immersion to convey information efficiently and
                        memorably – so much so that I strongly believe mixed
                        reality will become ubiquitous. With this epiphany, I
                        left my job last year to study this medium critically
                        and to design exciting new interfaces for 3D.
                    </p>
                    <p className="mb-5">
                        Historically, any new platform such as the internet,
                        the smartphone, and the personal computer has only
                        reached the masses because of its newfound ability to
                        connect people together. We see the beginnings of this
                        in mixed reality; niche groups of people today meet in
                        virtual environments to game and find like-minded
                        people. But it hasn’t truly reached the common person
                        yet, and I believe it is because of avatars.
                    </p>
                    <p className="mb-5">
                        Among market research and informal conversations I’ve
                        had along the way, it’s clear that cartoon avatars pose
                        a barrier to true co-presence. People don’t want to
                        talk to cartoons, even though they know that the person
                        behind the cartoon is real – cartoons force people to
                        acknowledge the presence of the medium, whereas the
                        best technologies are invisible when done right. At the
                        same time, cartoon avatars exist because of the
                        technical difficulty in developing photorealistic
                        avatars.
                    </p>
                    <p className="mb-5">
                        Coral’s goal is to provide a digital space where people
                        can meet up with their counterparts without ever
                        feeling like the interaction is happening virtually.
                        With Coral’s co-presence platform, users can strengthen
                        relationships with close ones and meet new people in a
                        natural way without the burden of travel.
                    </p>
                    <p className="mb-5">
                        Photorealistic avatars are only possible with a tight
                        integration between mixed reality headset sensors and
                        novel deep learning techniques. The section below shows
                        a few preliminary demos.
                    </p>
                    <p className="mb-5">
                        Note that I use the terms "mixed reality" (MR) and
                        "virtual reality" (VR) interchangeably because for
                        initial versions of Coral, the environment will be
                        entirely virtual. Later versions will incorporate
                        mixed reality environments.
                    </p>
                </div>
            </div>
            <div className="pt-28">
                <h2 className="mb-4 font-sans text-sm font-bold tracking-widest text-orange-600" id="landing2_header">DEMO</h2>
                <h1 className="font-sans text-3xl font-medium mb-14" id="landing2">
                    Developing photorealistic avatars with machine learning
                </h1>
                <div className="font-sans">
                    <p className="mb-5">
                        Developing photorealistic avatars for VR is a challenge
                        because (1) facial features must be detected from VR
                        headset sensors, (2) robust and efficient 3D facial
                        reconstruction is needed to build a 3D face, and (3)
                        all of this data must be transmitted to other users in
                        real-time as lag is disruptive to the user experience.
                        (1) is an especially interesting problem as the user's
                        face is occluded by the headset, making it difficult to
                        estimate facial expression parameters. Fortunately,
                        recent developments in computer graphics and compression
                        algorithms using machine learning make all three steps
                        technically feasible.
                    </p>
                    <p className="mb-5">
                        A preliminary demo of (1) and (2) is shown below. Here,
                        I successfully use Oculus Quest 2 sensor readings to
                        reconstruct my face in real-time using state-of-the-art
                        machine learning. The result shown here has not been
                        postprocessed and stitched to a human body. Clearly,
                        glasses aren't accounted for either.
                    </p>
                    <Image filename="coral1.gif" />
                    <p className="mb-5">
                        A preliminary demo of (3) is shown below. Here, I record
                        my face using the Oculus Quest sensor readings and
                        replay them in real-time in a 3D application. Similar to
                        above, the result here does not show postprocessing and
                        stitching to a human body.
                    </p>
                    <Image filename="coral2.gif" />
                    <p className="mb-5">
                        These results are from early February 2023 and do not
                        reflect the current state of the tech; more recent
                        demos and technical documents are available upon
                        request via <Hyperlink
                            text="email"
                            link="mailto:edward@edwardahn.me"
                        />.
                    </p>
                </div>
            </div>
            <div className="py-28">
                <h2 className="mb-4 font-sans text-sm font-bold tracking-widest text-orange-600" id="landing3_header">CONTACT</h2>
                <h1 className="font-sans text-3xl font-medium mb-14" id="landing3">
                    Interested in connecting with me?
                </h1>
                <div className="font-sans">
                    <p className="mb-5">
                        I've been exploring mixed reality from the lens of AI
                        and ML since I quit my job at Apple in January 2022. My
                        work can be seen <NavLink
                            to="/other"
                            className="text-orange-600 hover:underline"
                        >here</NavLink>. I also have a <Hyperlink
                            text="personal website"
                            link="https://edwardahn.me"
                        /> for work prior to 2022.
                    </p>
                    <p className="mb-5">
                        I love meeting with new people and am always happy to
                        have a chat in-person or virtually. Please don't
                        hesitate to <Hyperlink
                            text="email"
                            link="mailto:edward@edwardahn.me"
                        /> me. You can also reach me via <Hyperlink
                            text="LinkedIn"
                            link="https://www.linkedin.com/in/edwardsahn/"
                        />.
                    </p>
                </div>
            </div>
        </div>
    );
};
