import { useState } from 'react';

export default function Image({ filename }) {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            { loaded ? null : (
                <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] mt-5 mb-10" role="status">
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                    </span>
                </div>
            )}
            <img
                src={ require(`../assets/${filename}`) }
                alt={ `Embedded content with filename ${filename}` }
                className={ loaded ? "relative w-[80%] my-10 left-[7%]" : "hidden" }
                onLoad={() => setLoaded(true)}
            />
        </>
    );
};
