import { BrowserRouter, NavLink, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Landing from './pages/landing';
import TableOfContents from './components/TableOfContents';
import OtherDemos from './pages/other';

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <div className="min-h-screen bg-orange-100">
                <div className="flex pt-6 mx-10 sm:mx-20">
                    <NavLink to="/" className="text-3xl font-black tracking-wider cursor-pointer font-manrope">
                        CORAL
                    </NavLink>
                </div>
                <div className="flex flex-col lg:flex-row-reverse">
                    <TableOfContents />
                    <Routes>
                        <Route exact path="/" element={<Landing />} />
                        <Route path="/other" element={<OtherDemos />} />
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
