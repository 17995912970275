export default function YouTubeVideo({ videoId }) {
    return (
        <iframe
            className="relative w-[90%] my-10 left-[3%] aspect-video"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube Video"
        />
    );
};
